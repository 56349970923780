.right-section {
  /* background-color: #fff; */
  border-radius: 10px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  width: 48%;
}

/* Logo Image */
.logo {
  width: 100px;
  /* You can adjust the size as needed */
  margin-bottom: 10px;
  object-fit: contain;
}

/* Form Styling */
.shipping-form .form-group {
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.apply-button {
  height: 80%;
  background-color: var(--clr-main);
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 17px;
}

.form-row {
  display: flex;
  gap: 10px;
}

.checkbox-container {
  font-size: 14px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: var(--clr-main);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #3700b3;
}

/* Right Section (Order Summary) */
.product-info-right {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 3rem;
}

.product-image img {
  width: 60px;
  height: 60px;
  /* border-radius: 5px; */

  margin-right: 10px;
  border: 0.5px solid #ffe4de;
  object-fit: contain;
}

.product-price .original-price {
  text-decoration: line-through;
  color: red;
  font-size: 14px;
}

.product-price .discounted-price {
  color: #000;
  font-size: 14px;

  /* font-weight: bold; */
}

.discount-code-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.discount-code-button {
  background-color: var(--clr-main);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.apply-button:hover,
.discount-code-button:hover {
  background-color: var(--clr-main);
}

.order-summary2 {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  margin: 5px 0;
  /* max-width: 500px; */
  max-width: 485px;
}

.price {
  /* font-weight: bold; */
  font-size: 12px;
}

.price.free {
  color: green;
}

.total-savings {
  margin-top: 20px;
  text-align: center;
  color: green;
}

.final-total {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

/* Shipping > Payment Text */
.breadcrumb {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
  color: #777;
}

.order-summary2 {
  /* background-color: #fff; */
  padding: 25px;
  border-radius: 10px;
}

.order-summary2 h2 {
  font-size: 20px;
  margin-bottom: 20px;
}
.order_summary_img {
  background-color: #ffff;
  padding: 14px;
  width: 100px;
}
.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  /* margin-top: 1.5rem; */
}

.item img {
  width: 64px;
  height: 68px;
  border-radius: 6px;
}

.item p {
  margin: 0;
  font-size: 15px;
}

.price span {
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
}

.price span:first-child {
  color: #999;
  text-decoration: line-through;
}

.total {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #ccc;
}

.total p {
  margin: 0;
  font-size: 16px;
  color: #999;
}

.total h3 {
  margin: 10px 0 0;
  font-size: 20px;
  font-weight: bold;
}

.star_icon {
  color: #5f60b0;
}
.savings-banner {
  background-color: var(--clr-main);
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 5px;
}

.savings-banner img {
  margin-right: 10px;
}

.div_card {
  display: flex;
  justify-content: space-between;
  width: 78%;
  /* align-items: center; */
}
.div_card p {
  font-weight: 500;
  max-width: 69%;
}

.total-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* padding-top: 10px; */
  padding: 20px;
}

.total-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #5f60b0;
}

.total-details img {
  margin-right: 10px;
  color: #999;
}

.total-details span {
  font-size: 16px;
  font-weight: 600;
}

.expand-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.expand-button span {
  font-size: 20px;
}
.features > ul > li {
  font-weight: 300;
}
.order_summery_hr {
  margin-bottom: 20px;
}
/* Responsive Design */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-section,
  .right-section {
    width: 100%;
  }
  .order-summary2 {
    padding: 0px;
    max-width: 100%;
  }
  /* ṇew */
  .item {
    padding: 0 20px;
  }
  .order-summary-title {
    padding: 22px 20px 0;
  }
  .order_summery_hr {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .your-total-div {
    width: 90%;
    margin: auto;
    margin-bottom: 21px;
  }
}

.features {
  text-align: left;
  margin-top: 20px;
  margin-left: 14px;
}

.features ul {
  padding: 0;
  margin: 0;
  list-style-type: disc;
}

.features li {
  margin-bottom: 10px;
}

.ssl-secured-payment {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  gap: 5px;
  margin-bottom: 10px;
}

.ssl-secured-payment .fa-lock {
  margin-right: 5px;
  color: #007bff;
}

.details_of_checkbox {
  position: absolute !important;
  top: 0px;
  right: 0px;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-main);
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transform: scale(0.8) !important;
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -ms-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* Style the checkbox when it's checked */
.details_of_checkbox:checked::before {
  content: "✔";
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--color-main);
  color: var(--color-white);
  text-align: center;
  line-height: 20px;
  border-radius: 25px;
  position: relative;
  bottom: 10px;
  right: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.remove_coupen_btn {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 17px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
