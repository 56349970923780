/* src/components/Navbar.css */
.navbar {
  display: flex;
  align-items: center;
  /* display: grid; */
  /* align-items: initial; */
  /* grid-template-columns: 2fr 1fr 2fr; */
  justify-content: space-between;
  padding: 1rem 3rem;
  background-color: #000;
  color: #fff;
  font-weight: 700;
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.4;
}

.navbar-brand .logo {
  height: 60px;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 20px;
  position: relative;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-item:hover .dropdown {
  display: block;
}

.dropdown-content {
  display: flex;
}

.dropdown-section {
  margin-right: 20px;
}

.dropdown-section h3 {
  margin: 0 0 10px 0;
  color: var(--clr-main);
}

.dropdown-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-section ul li {
  margin-bottom: 10px;
}

.dropdown-section ul li a {
  color: #fff;
  text-decoration: none;
}

.navbar-signin {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.signin-btn {
  background-color: var(--clr-main);
  color: #000;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  border: 3px solid var(--clr-main);
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .navbar-brand {
    flex: none;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 88px;
    left: 0;
    width: 100%;
    background-color: #000;
    flex-direction: column;
    text-align: center;
    padding: 1rem 10px;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-item {
    margin: 10px 0;
  }

  .hamburger {
    /* display: block; */
  }

  .navbar-brand .logo {
    /* height: 30px; */
  }

  .signin-btn {
    padding: 10px 1rem;
    font-size: 12px;
  }

  .nav_eampy_div {
    display: none;
  }
  /* new */
  .navbar-signin {
    flex: 0.6 1;
  }
}
