@font-face {
  font-family: "Gibson";
  font-weight: 100;
  src: url(./utils/fonts/gibson/fonnts.com-Gibson_Thin.otf) format("opentype");
}
@font-face {
  font-family: "Gibson";
  font-weight: 300;
  src: url(./utils/fonts/gibson/fonnts.com-Gibson_Light.otf) format("opentype");
}
@font-face {
  font-family: "Gibson";
  font-weight: 400;
  src: url(./utils/fonts/gibson/fonnts.com-Gibson_Regular.otf)
    format("opentype");
}
@font-face {
  font-family: "Gibson";
  font-weight: 500;
  src: url(./utils/fonts/gibson/fonnts.com-Gibson_Medium.otf) format("opentype");
}
@font-face {
  font-family: "Gibson";
  font-weight: 600;
  src: url(./utils/fonts/gibson/fonnts.com-Gibson_SemiBold.otf)
    format("opentype");
}
@font-face {
  font-family: "Gibson";
  font-weight: 700;
  src: url(./utils/fonts/gibson/fonnts.com-Gibson_Bold.otf) format("opentype");
}
@font-face {
  font-family: "Gibson";
  font-weight: 900;
  src: url(./utils/fonts/gibson/fonnts.com-Gibson_Heavy.otf) format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

:root {
  /* dark shades of primary color*/
  /* --font-base: "Poppins", sans-serif; */

  --font-base: "Gibson";

  --clr-main: #a4d293;

  --clr-white: #fff;
  --clr-gray: #555;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #000;
  --clr-black-blue: #292731;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1040px;
  --fixed-width: 620px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
  max-width: 100vw;
  /* font-family: "Lato", sans-serif !important; */
}

::after,
::before {
  box-sizing: border-box;
}

body {
  font-family: var(--font-base);
  background: var(--color-bg);
  overflow-x: hidden;
}

figure {
  margin: 0px !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: var(--font-base);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: unset;
  text-decoration: none;
}

.underline {
  text-decoration: underline !important;
}

.underline:hover {
  text-decoration: none !important;
}

strong,
b {
  font-weight: 600;
}

i {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.hr {
  width: 100%;
  height: 2px;
  background: var(--color-light-gray);
  margin: 1rem auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="checkbox"] {
  transform: scale(1.3);
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

button[type="submit"] {
  cursor: pointer;
  border: none;
}

button:disabled {
  cursor: not-allowed;
  opacity: 80%;
}

textarea {
  resize: none;
}

a {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

.section {
  padding: 4rem 3rem;
}

@media (max-width: 768px) {
  .section {
    padding: 3rem 1rem;
  }
}

/* loder start */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}
/* loder end*/

/* buttons start */

.btn {
  /* text-transform: uppercase; */
  background: var(--clr-main);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  /* letter-spacing: var(--spacing); */
  display: inline-block;
  font-weight: 600;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  border-radius: var(--radius);
  border-color: transparent;
  width: 100%;
  height: 38px;
  max-width: 400px;
}

.btn2 {
  /* text-transform: uppercase; */
  background: var(--clr-main);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  /* letter-spacing: var(--spacing); */
  display: inline-block;
  font-weight: 400 !important;
  transition: var(--transition);
  font-size: 1.2rem;
  cursor: pointer;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  border-radius: var(--radius);
  border-color: transparent;
  width: 100%;
  height: 58px;
  /* max-width: 400px; */
}

.btn:hover {
  /* box-shadow: 0px 8px 12px 0px #fd74014d; */
  transition: 0.3s ease-in all;
  background: var(--clr-white);
  color: var(--clr-black);
  border: 1px solid var(--clr-black);
}

.mb-2 {
  margin-bottom: 2rem;
}

.fw-500 {
  font-weight: 500;
}
