/* src/PaymentGetways.css */

.payment-header_p {
  padding: 1rem 0rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.paymen_p_tag {
  font-size: 25px;
}
.back_menu {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #464643;
  font-weight: 500;
}
.payment-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0px 20px;
  max-width: var(--max-width);
  /* margin-bottom: 2rem; */
}

.payment-content {
  max-width: var(--max-width);
  /* margin: auto; */
  gap: 10px;
}
.payment-content2 {
  display: flex;
  justify-content: space-between;
  max-width: var(--max-width);
  /* margin: auto; */
  gap: 30px;
}
.pac-item-query {
  font-family: var(--font-base);
  font-weight: 500;
}
.container {
  display: flex;
  justify-content: space-between;
  max-width: var(--max-width);
  /* margin: auto; */
  gap: 10px;
}

/* Form and Order Summary */
.checkout-form {
  width: 100%;
  /* background: white;
  padding: 20px;
  border-radius: 5px; */
  /* border-right: 0.1px solid #cacacabb; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.checkout-form-wrapp {
  width: 60%;
  background: white;
  padding: 20px;
  border-radius: 5px;
  /* border-right: 0.1px solid #cacacabb; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

/* Headings */
.checkout-form h2,
.checkout-form h3 {
  margin-bottom: 14px;
  color: #333;
  padding-top: 8px;
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 18px;
  font-weight: 300;
}

/* Form Group Styles */
.form-group {
  margin-bottom: 8px;
  font-weight: 300;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.inline_seond {
  width: 50%;
}

/* Individual Form Groups within Inline Form Group */
.form-group-inline .form-group {
  width: 50%;
}

input,
select {
  width: 100%;
  padding: 14px 20px;
  /* background-color: rgba(128, 128, 128, 0.09); */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background: none;
}
select {
  background-image: url("../../assets/logos/down.png"); /* Arrow Icon for the dropdown */
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-size: 12px;
  margin-bottom: 8px;
}

/* Button Styles */
.btn-checkout {
  background-color: var(--clr-main);
  color: #fff;
  margin-top: 15px;
  padding: 10px 20px;
  border: 3px solid var(--clr-main);
  border-radius: 2rem;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* letter-spacing: 1px; */
  text-align: center;
}

.btn-checkout:hover {
  /* border: 1px solid var(--clr-main);/ */
  background-color: #fff;
  color: #000;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

/* Link Styles */
.previous-step {
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
  color: #007f5f;
}

/* Order Summary Styles */

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 15px;
  font-weight: 300;
  line-height: 20px;
  align-items: center;
  width: 100%;
}

.details_order p {
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.img_order {
  width: 30%;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: #005f4a;
  border-radius: 5px;
}

.item_img {
  object-fit: contain;
  max-width: 100px;
  height: 100px;
}

.img_order_del {
  width: 80%;
}

.img_order_del span {
  font-size: 13px;
  line-height: 25px;
  font-weight: 600;
  color: #484848;
}

.img_order_del p {
  font-size: 12px;
  font-weight: 500;
}

.img_order_del b {
  font-size: 12px;
  font-weight: 600;
}

.total_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.summary-totals {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
}

.summary-totals p {
  /* font-weight: bold; */
  color: #333;
}
.payment_card_titel b {
  display: flex;
  gap: 15px;
  align-items: center;
  font-weight: 500;
  color: #464643;
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

input[type="checkbox"] {
  margin-right: 10px;
  /* background: black; */
}

input[type="checkbox"]:checked {
  /* margin-right: 10px; */
  background: black;
  /* width: 18px; */
  /* height: 18px; */
}

input[type="radio"]:checked {
  /* margin-right: 10px; */
  background: black;
  /* width: 18px; */
  /* height: 18px; */
}

label {
  font-size: 14px;
  color: #333;
}

/* Payment Method Styles */
#payment-method {
  cursor: pointer;
}

.hr {
  border: 1px solid #484848;
  margin-bottom: 15px;
  background: none;
  margin: 0 0 15px 0;
  height: 0;
}

.terms_policy {
  color: #00c0ef;
  cursor: pointer;
}
.note_titel_ {
  width: 100%;
}
.note_titel_ p {
  font-size: 14px;
}
.payment_card {
  /* border: 1px solid #959292; */
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.payment_card_titel {
  /* border-bottom: 1px solid #333; */
  /* padding: 15px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.card_icon_div {
  display: flex;
  gap: 10px;
}
.details-card {
  /* padding: 0.8rem 0.8rem; */
}

/* tabel */

.order-summary {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-collapse: collapse;
  padding: 20px;
  border-radius: 5px;
  /* background-color: #fafafa; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  */
}
.order-table {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}
.order-table th,
.order-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.order-table th {
  font-weight: 600;
  text-align: center;
}

.item-details {
  display: flex;
  align-items: center;
}

.item-img {
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: var(--clr-main);
  border-radius: 5px;
  margin-right: 20px;
}

.item-img img {
  width: 100px;
  height: 100px;
  /* object-fit: cover; */
}

.item-description span {
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  color: #484848;
}

.item-description p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.center_line {
  width: 0.5px;
  height: inherit;
  background: #a9a8a8;
}

/* promocode start */

.promo_input {
  width: 68%;
  min-width: 100px;
}

/* promocode end */

/* ------------ new css start ---------- */
.checkout_logo_wrapp {
  width: 30%;
  min-width: 150px;
}
.checkout_logo_wrapp > img {
  width: 130px;
  /* height: 80px; */
  object-fit: contain;
}

.checkout_head_name_wrapp {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 0 1rem 0;
  gap: 10px;
}

.checkout_head_name_wrapp > p {
  margin: 0px;
  color: #212529;
  font-weight: 300;
}

/* checkout box start */

.sec2_checkout_box_wrapp {
  width: 100%;
  padding: 0px 24px;
  border: 1px solid #e5e7eb;

  display: flex;
  flex-direction: column;
  margin: 1rem auto;
}

.sec2_checkout_box_wrapp > div {
  padding: 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7eb;
  gap: 10px;
}

.sec2_checkout_box_wrapp > div > p {
  font-size: 15px;
  color: #212529;
  font-weight: 300;
}

.sec2_checkout_box_wrapp > div > button {
  font-size: 15px;
  color: #212529;
  font-weight: 300;
  text-decoration: underline;
}

/* checkbox end */

/* payment start */

.pg2_heading {
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  color: #212529;
}

.pg2_des {
  font-size: 14px;
  line-height: 125%;
  font-weight: 300;
  color: #212529;
}

.paymentbox_main_wrapp {
  margin: 1rem auto;
  width: 100%;
  /* border: 1px solid #e5e7eb; */
}

.paymentbox_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  background: #fff;
  border: 1px solid #e5e7eb;
}

.paymentbox_contain {
  --tw-bg-opacity: 1;
  padding-top: 15px;
}
/* payment end */

/* address box start */

.addressbox_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 13px 15px;
  background: #fff;
  border: 1px solid #e5e7eb;
}

.address_box_radio {
  padding: 0px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  cursor: pointer;
  accent-color: #000;
  position: relative;
  content: "";
  appearance: auto;
}

input[type="radio"]::before {
  /* ...existing styles */

  /* Windows High Contrast Mode */
  background-color: #000;
}
/* address box end */

.headline_for_mob {
  display: none;
}

.headline_for_mob2 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .headline_for_mob {
    display: flex;
  }

  .headline_for_mob2 {
    display: none !important;
  }
  .payment-column-reverse {
    flex-direction: column-reverse !important;
  }
  .hide_headline {
    display: none;
  }
}

/* skip modal start */

.skip_modal_main_wrapp {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: fixed;
  left: 0;
  top: 0;

  background: rgba(85, 85, 85, 0.399);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  /* border: 1px solid rgba(255, 255, 255, 0.18); */

  z-index: 1111;

  display: flex;
  align-items: center;
  justify-content: center;
}

.skip_white_part_wrapp {
  padding: 1rem;
  background: var(--clr-white);
  width: auto;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-radius: 8px;

  height: 200px;
  width: 300px;
}

.skip_white_part_wrapp > div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.skip_modal_btn1 {
  background: var(--clr-white);
  border: 1px solid var(--clr-main);
  color: var(--clr-black);
  font-size: 12px;
  font-weight: 500;
  padding: 10px 1rem;
  border-radius: 6px;
  transition: 0.2s ease all;
}

.skip_modal_btn1:hover {
  background: var(--clr-main);
  color: var(--clr-white);
}

.skip_modal_btn2 {
  background: var(--clr-white);
  border: 1px solid var(--clr-main);
  color: var(--clr-black);
  font-size: 12px;
  font-weight: 500;
  padding: 10px 1rem;
  border-radius: 6px;
  transition: 0.2s ease all;
}

.skip_modal_btn2:hover {
  background: var(--clr-main);
  color: var(--clr-white);
}

/* skip modal end */

/* ------------ new css end ---------- */

/* Large Screens (Desktops) */
@media screen and (min-width: 1024px) {
  .payment-content {
    max-width: var(--max-width);
  }

  /* .checkout-form,
  .order-summary {
    width: 50%;
    /* Form takes 70% 
  } */

  .order-summary {
    width: 50%;
    /* Summary takes 30% */
  }
}

/* Medium Screens (Tablets) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .payment-content {
    flex-direction: column;
    max-width: 750px;
  }

  .inline_seond {
    width: 100%;
  }

  .checkout-form,
  .order-summary {
    width: 100%;
    margin-bottom: 20px;
  }

  .form-group-inline {
    flex-direction: row;
  }

  .btn-checkout {
    font-size: 16px;
    padding: 12px;
  }
  .order-table {
    width: 100%;
  }
}

/* Small Screens (Mobile Devices) */
@media screen and (max-width: 767px) {
  .center_line {
    width: 100%;
    height: 0.5px;
    background: #a9a8a8;
  }

  .checkout_logo_wrapp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 150px;
  }
  .order-table {
    width: 100%;
  }
  .inline_seond {
    width: 100%;
  }

  .payment-content {
    flex-direction: column;
    width: 100%;
  }
  .payment-content2 {
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    /* height: 100%; */
  }
  .payment_card_titel b {
    font-size: 14px;
    gap: 5px;
  }
  .checkout-form-wrapp {
    padding: 0px;
    width: 100%;
  }
  .checkout-form,
  .order-summary {
    width: 100%;
    margin-bottom: 20px;
    /* padding: 15px; */
  }

  .checkout-form h2,
  .checkout-form h3 {
    font-size: 18px;
  }

  .form-group-inline {
    flex-direction: column;
    margin-bottom: 8px;
    gap: 0px;
  }

  .form-group-inline .form-group {
    width: 100%;
    margin-bottom: 10px;
  }

  .form-group input,
  .form-group select {
    font-size: 16px;
    /* padding: 10px; */
  }

  .btn-checkout {
    font-size: 16px;
    padding: 12px;
  }

  .item_img {
    width: 70px;
    height: 60px;
  }

  .summary-totals p {
    font-size: 14px;
  }
}

#stickyio_cc_number {
  color: blue;
}
#stickyio_cc_expiry {
  color: green;
}
#stickyio_cc_cvv {
  color: red;
}
